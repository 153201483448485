import React, { useState } from "react";
import Header from "../components/Header";
import Head from "../components/Head";
import Footer from "../components/Footer";
import BoxTypo from "../components/BoxTypo";
import { Box, Grid, Button, Stack } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useForm } from "react-hook-form";
import Forms from "../components/Forms";
import ValidationView from "../components/FormsValidate";
import FormsOriginalOrder from "../components/FormsOriginalOrder";
import FormsOriginalOrderValidate from "../components/FormsOriginalOrderValidate";
import "../style/forms.css";
import "../style/base.css";
import {
  StaticImage,
  GatsbyImage,
  getImage,
  DynamicImage,
} from "gatsby-plugin-image";
import { graphql, useStaticQuery, Link } from "gatsby";
import OrderFaq from "../components/OrderFaq";

const H2 = (props) => {
  return (
    <BoxTypo
      m="56px auto"
      fs={["24px", "26px"]}
      ls={["2px"]}
      lh={["200%"]}
      fw={["500"]}
      color={"#515D59"}
      className="tac"
    >
      {props.children}
    </BoxTypo>
  );
};
const H3 = (props) => {
  return (
    <BoxTypo
      {...props}
      mt={6}
      fs={["18px", "20px"]}
      ls={["1px"]}
      lh={["200%"]}
      fw="500"
      color={"#515D59"}
    >
      {props.children}
    </BoxTypo>
  );
};
const P = (props) => {
  return (
    <BoxTypo
      {...props}
      fs={["14px", "16px"]}
      ls={["2px"]}
      lh={["200%"]}
      color={props.color ? props.color : "#515D59"}
    >
      {props.children}
    </BoxTypo>
  );
};

const Main = () => {
  const data = useStaticQuery(
    graphql`
      query {
        first_view_1: file(relativePath: { eq: "catering/raito_plane.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_2: file(
          relativePath: { eq: "catering/standard_plane.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_3: file(
          relativePath: { eq: "catering/premiamu_plane.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        first_view_4: file(
          relativePath: { eq: "catering/after_noon_plane.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const Plane = ({ num, title, secondTitle, thirdTitle, menuList, img }) => {
    return (
      <Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "stretch",
            width: "100%",
            maxWidth: "1100px",
            mx: "auto",
            mt: { xs: 0, md: "64px" },
          }}
        >
          <Box
            width={"60%"}
            style={{ flex: "1" }}
            bgcolor={"#F2F8F7"}
            zIndex={"99"}
            padding={"5% 5% 0 10%"}
          >
            <Box bgcolor={"#fff"} padding={"10%"} marginRight={"-100px"}>
              <hr
                width="60px"
                color="#F6C5C4"
                style={{ display: "inline-block", marginRight: "30px" }}
              />
              <BoxTypo
                fs={["30px"]}
                ls={["2px"]}
                lh={["200%"]}
                color={"#F6C5C4"}
                style={{ display: "inline-block" }}
              >
                0{num}
              </BoxTypo>
              <BoxTypo fs={["18px"]} ls={["2px"]} lh={["200%"]} fw={["700"]}>
                {title}
              </BoxTypo>
              <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]} color="#B4B4B4">
                {secondTitle}
              </BoxTypo>
              <BoxTypo
                fs={["13px"]}
                ls={["2px"]}
                lh={["200%"]}
                color="#888888"
                fw={["700"]}
              >
                {thirdTitle}
              </BoxTypo>
            </Box>
            <Box padding={"10%"}>
              <BoxTypo fs={["14px"]} ls={["2px"]} lh={["200%"]} fw={["700"]}>
                メニュー例
              </BoxTypo>
              <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]} color="#000">
                <ul class="plane" style={{ color: "#000" }}>
                  {menuList.map((c, i) => {
                    return <li>{c}</li>;
                  })}
                </ul>
                ※メニューは一例で、変更になる可能性がありますのでご了承ください
              </BoxTypo>
            </Box>
          </Box>
          <Box width={"40%"} style={{ flex: "1" }} zIndex={"0"}>
            <Box className="w90" height={"100%"} bgcolor={"#F2F8F7"}>
              <GatsbyImage
                image={getImage(img)}
                style={{ height: "95%", zIndex: 0, marginRight: "-10%" }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            width: "100%",
            maxWidth: "1100px",
            mx: "auto",
            color: "#fff",
          }}
        >
          <Box width={"95%"} m={"auto"}>
            <Box>
              <GatsbyImage
                image={getImage(img)}
                style={{
                  width: "65%",
                  margin: "0px auto 0px auto",
                }}
              />
            </Box>
            <Box p={"5%"}>
              <BoxTypo
                fs={["30px"]}
                ls={["2px"]}
                lh={["200%"]}
                color={"#F6C5C4"}
                style={{ display: "inline-block" }}
              >
                0{num}
              </BoxTypo>
              <BoxTypo fs={["18px"]} ls={["2px"]} lh={["200%"]} fw={["700"]}>
                {title}
              </BoxTypo>
              <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]} color="#B4B4B4">
                {secondTitle}
              </BoxTypo>
              <BoxTypo
                fs={["13px"]}
                ls={["2px"]}
                lh={["200%"]}
                color="#888888"
                fw={["700"]}
              >
                {thirdTitle}
              </BoxTypo>
              <BoxTypo
                fs={["14px"]}
                ls={["2px"]}
                lh={["200%"]}
                fw={["700"]}
                mt={4}
              >
                メニュー例
              </BoxTypo>
              <BoxTypo
                fs={["12px"]}
                ls={["2px"]}
                lh={["200%"]}
                color="#000"
                pb={12}
              >
                <ul class="plane" style={{ color: "#000" }}>
                  {menuList.map((c, i) => {
                    return <li>{c}</li>;
                  })}
                </ul>
                ※メニューは一例で、変更になる可能性がありますのでご了承ください
              </BoxTypo>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  const [isOpenModal, setOpenModal] = useState(false);
  const [inputData, setData] = useState({});
  const onSubmit = (data) => console.log(data);

  const [totalPrice, setTotalPrice] = useState(0);
  return (
    <>
      <Head />
      <Header />
      <Box display={isOpenModal ? "none" : "block"}>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <StaticImage
            src="../images/originalorder_top_sp.png"
            style={{ width: "100%", objectFit: "cover" }}
            alt="First View"
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <StaticImage
            src="../images/originalorder_top_pc.png"
            style={{ width: "100%", objectFit: "cover" }}
            alt="First View"
          />
        </Box>
        <Box py={7} width={"100%"} bgcolor={"#F3F5F5"}>
          <Box
            width={"100%"}
            maxWidth={"1100px"}
            px={["10px", "20px"]}
            className="tac ma"
          >
            <P textAlign={["left", "center"]}>
              以下よりケータリングプランのご注文を承ります。
              <br />
              ご依頼内容を情報を入力のうえご依頼ボタンを押してください。
              <br />
            </P>
            <P
              mt={2}
              textAlign={["left", "center"]}
              style={{ fontSize: "14px" }}
            >
              ※ご依頼は基本的に10日前まで承ります。
              <br />
              ※基本的に、配送は東京23区のみ対応しております。
              <br />
              ※会場がWeWork以外のケータリングプランは3万円(税込)以上のオーダーから承ります。
              <br />
              ※20名様未満のご注文はお食事の料金とは別に、配送・セッティング/撤収手数料を一律1万円(税込)をいただいております。ご了承くださいませ。
              <br />
              ※日程や人数が確定していない場合も、まずは暫定の情報を以下フォームにご登録ください。
              <br />
              <br />
              WeWork内及び、Cafe1968をご利用のお客様は、以下フォームよりご注文ください。
              <br />
              <Box className="df tac jcc pc">
                <BoxTypo color="red">
                  <Link
                    to="https://forms.gle/qAdRLJA9CH2n4Tnx8"
                    style={{ color: "#515d59" }}
                  >
                    WeWork Iceberg
                  </Link>
                </BoxTypo>
                <BoxTypo>/</BoxTypo>
                <BoxTypo>
                  <Link
                    to="https://forms.gle/jJn2ketAhgaEKzy19"
                    style={{ color: "#515d59" }}
                  >
                    WeWork Shibuya
                  </Link>
                </BoxTypo>
                <BoxTypo>/</BoxTypo>
                <BoxTypo>
                  <Link
                    to="https://forms.gle/m4QkvfP5TXSboYTA6"
                    style={{ color: "#515d59" }}
                  >
                    WeWork Kamiyacho
                  </Link>
                </BoxTypo>
                <BoxTypo>/</BoxTypo>
                <BoxTypo>
                  <Link
                    to="https://forms.gle/93dB3CSs8mhqGHvo8/"
                    style={{ color: "#515d59" }}
                  >
                    Cafe1968/
                  </Link>
                </BoxTypo>
              </Box>
              <Box className="tal jcc sp ma" ml={4}>
                <ul className="store">
                  <li>
                    <BoxTypo>
                      <Link
                        style={{ color: "#515d59" }}
                        to="https://forms.gle/qAdRLJA9CH2n4Tnx8"
                      >
                        WeWork Iceberg
                      </Link>
                    </BoxTypo>
                  </li>
                  <li>
                    <BoxTypo>
                      <Link
                        style={{ color: "#515d59" }}
                        to="https://forms.gle/jJn2ketAhgaEKzy19"
                      >
                        WeWork Shibuya
                      </Link>
                    </BoxTypo>
                  </li>
                  <li>
                    <BoxTypo>
                      <Link
                        style={{ color: "#515d59" }}
                        to="https://forms.gle/m4QkvfP5TXSboYTA6"
                      >
                        WeWork Kamiyacho
                      </Link>
                    </BoxTypo>
                  </li>
                  <li>
                    <BoxTypo>
                      <Link
                        style={{ color: "#515d59" }}
                        to="https://forms.gle/93dB3CSs8mhqGHvo8/"
                      >
                        Cafe1968
                      </Link>
                    </BoxTypo>
                  </li>
                </ul>
              </Box>
              <br />
            </P>
          </Box>
        </Box>
        <Box sx={{ bgcolor: { xs: "#F3F5F5", md: "#fff" } }}>
          <Plane
            num={"1"}
            title={"ライトプラン"}
            secondTitle={"軽めのお食事プラン"}
            thirdTitle={"2,500円/お一人様 (全6品)"}
            menuList={[
              "ズッキーニ、モッツァレラ、生ハムのピンチョス",
              "ローストビーフ、大葉、チェリートマトのピンチョス",
              "ミニバーガー〜オリジナルフラッグ付き〜",
              "トリュフチップス&スモークナッツ",
              "ソーセージとインカのめざめのハーブソテー",
              "ブラウニー",
            ]}
            img={data.first_view_1}
          />
          <Plane
            num={"2"}
            title={"スタンダードプラン"}
            secondTitle={
              "前菜、メイン、デザートまでバランスよく入ったお食事プラン"
            }
            thirdTitle={"3,000円/お一人様 (全8品)"}
            menuList={[
              "ズッキーニ、モッツァレラ、生ハムのピンチョス",
              "ローストビーフ、大葉、チェリートマトのピンチョス",
              "ミニバーガー〜オリジナルフラッグ付き〜",
              "トリュフチップス&スモークナッツ",
              "サーモン、マスカルポーネのブルスケッタ",
              "鶏の柚子胡椒照焼",
              "ソーセージとインカのめざめのハーブソテー",
              "名物フレンチトーストタワーorブラウニー",
            ]}
            img={data.first_view_2}
          />
          <Plane
            num={"3"}
            title={"プレミアムプラン"}
            secondTitle={"しっかりお食事プラン"}
            thirdTitle={"3,500円/お一人様 (全10品)"}
            menuList={[
              "ズッキーニ、モッツァレラ、生ハムのピンチョス",
              "ローストビーフ、大葉、チェリートマトのピンチョス",
              "ミニバーガー〜オリジナルフラッグ付き〜",
              "トリュフチップス&スモークナッツ",
              "サーモン、マスカルポーネのブルスケッタ",
              "鶏の柚子胡椒照焼",
              "鮭の塩麹焼き",
              "ソーセージとインカのめざめのハーブソテー",
              "かぼちゃとひじきの焼きコロッケ",
              "名物フレンチトーストタワーorブラウニー",
            ]}
            img={data.first_view_3}
          />
        </Box>
        <Box>
        <Plane
            num={"4"}
            title={"アフタヌーンティープラン"}
            secondTitle={"ティータイムにスイーツメインのプラン"}
            thirdTitle={"2,500円/お一人様(全６種類/お一人様あたり５品/30名様以上でご注文可能)"}
            menuList={[
              "ミニバーガー",
              "ミニマフィン",
              "ブラウニー",
              "グラノーラバー",
              "パンナコッタ",
              "名物フレンチトーストタワー",
            ]}
            img={data.first_view_4}
          />
        </Box>
        <Box
          id="order-form"
          width={"100%"}
          maxWidth={"1100px"}
          px={["10px", "20px"]}
          className="ma tac"
        >
          <H3>ご注文者様の情報を入力して下さい</H3>
          <FormsOriginalOrder setOpenModal={setOpenModal} setData={setData} />
        </Box>
      </Box>
      <Box display={isOpenModal ? "block" : "none"}>
        <FormsOriginalOrderValidate
          inputData={inputData}
          setOpenModal={setOpenModal}
        />
      </Box>

      <OrderFaq />
      <Footer />
    </>
  );
};

const Food = (props) => {
  const { orderNum, setOrderNum, id, price, name, sumPrice } = props;
  return (
    <Box width={"100%"} mt={3}>
      <img
        alt="img"
        src={props.img}
        style={{ width: "100%", objectFit: "cover" }}
      />
      <Box className="df jcsb" width={"100%"} mt={1}>
        <Box width={"40%"}>
          <BoxTypo
            color={"#515D59"}
            fs={["12px"]}
            ls={["2px"]}
            lh={["200%"]}
            fw="300"
          >
            ¥{price}
          </BoxTypo>
          <BoxTypo color={"#515D59"} fs={["16px"]} ls={["2px"]} lh={["200%"]}>
            {name}
          </BoxTypo>
        </Box>
        <Box width={"60%"} className="df jcsb alis tar mr">
          <Box
            width={"40%"}
            className="mr"
            style={{
              height: "100%",
              background: "#F3F5F5",
              color: "#515D59",
              border: "none",
            }}
          >
            <BoxTypo className="tac" fs={["14px"]} ls={["2px"]} mt={"14px"}>
              {orderNum[id]}
            </BoxTypo>
          </Box>
          <Box width={"15%"}>
            <button
              style={{
                height: "25px",
                width: "100%",
                border: "none",
                background: "none",
                color: "#515D59",
              }}
              onClick={(e) => {
                var temp = orderNum;
                temp[id] = Number(orderNum[id] < 99 ? orderNum[id] + 1 : 99);
                console.log({ temp, value: orderNum[id] });
                setOrderNum([...temp]);
                sumPrice();
              }}
            >
              <ArrowDropUpIcon />
            </button>
            <button
              style={{
                height: "25px",
                border: "none",
                background: "none",
                color: "#515D59",
              }}
              onClick={(e) => {
                var temp = orderNum;
                temp[id] = Number(orderNum[id] > 0 ? orderNum[id] - 1 : 0);
                console.log({ temp, value: orderNum[id] });
                setOrderNum([...temp]);
                sumPrice();
              }}
            >
              <ArrowDropDownIcon />
            </button>
          </Box>
          <Box style={{ width: "20%" }}>
            <BoxTypo
              color={"#515D59"}
              fs={["16px"]}
              ls={["2px"]}
              fw="300"
              mt={3}
            >
              個
            </BoxTypo>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Main;
